<template>
  <div>
    <van-popup v-model="show" position="bottom" :close-on-click-overlay="false">
      <div class="tibOX">
        <h4>您的手机号信息</h4>
        <div class="widhtbox">
          <van-form>
            <van-field
              v-model="mobile"
              type="tel"
              name="手机号码"
              placeholder="您的手机号码"
            />
            <van-field
              v-model="Verification"
              name="验证码"
              type="number"
              placeholder="验证码"
              clearable
              maxlength="6"
            >
              <template #button>
                <van-button
                  size="small"
                  type="default"
                  :disabled="isSmsSend"
                  @click="sendSmsCode"
                  >{{ sendBtnText }}</van-button
                >
              </template>
            </van-field>
            <div style="margin: 16px">
              <van-button
                block
                type="primary"
                native-type="submit"
                :disabled="isfort"
                @click="buttomClick"
                >允许绑定</van-button
              >
            </div>
          </van-form>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getWebCode, bindWechat } from "@/request/api";
import {getItem,setItem,removeItem} from "@/utils/storage";
import { Toast } from "vant";
import store from "@/store/index";
import { sendSms } from "@/request/api";
import{setCode} from '@/utils/fununi'

export default {
  data() {
    return {
      show: false,
      mobile: "",
      Verification: "",
      isfort: true,
      isSmsSend: false,
      sendBtnText: "获取验证码",
      timer: null,
      counter: 60,
      loadNum: "60",
    };
  },
  watch: {
    Verification(val, oldVal) {
      if (val.length >= 6) {
        this.isfort = false;
      } else {
        this.isfort = true;
      }
    },
  },
  created() {
    this.wewacetOath();
  },
  methods: {
    wewacetOath() {
      if (setCode("code") == ""||setCode("code") == null) {
        window.location.href =
          `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx45890dcad66a5e76&redirect_uri=https%3A%2F%2Fcrm.wwy-x.com%2F%23%2FWeWacetOath&response_type=code&scope=snsapi_userinfo&state=${getItem('url')}#wechat_redirect`;
      } else {
        Toast.loading({
          message: "登录中...",
          forbidClick: true,
          duration: 0, // 持续展示 toast
        });
        let data = {
          code:setCode("code"),
        };
        getWebCode(data).then((res) => {
          if (res.data.is_bind_mobile == "0") {
            store.dispatch("info", res.data.token);
            Toast.clear();
            this.show = true;
          } else if (res.data.is_bind_mobile == "1") {
            this.show = false;
            store.dispatch("wxOat", res.data);
            store.dispatch("info");
            Toast.clear();
            let href = window.location.href;
            var url = href.substring(0, href.length - 2); //vue自动在末尾加了 #/ 符号，截取去掉
            var jingPosit = url.indexOf("com/") + 4; //获取域名结束的位置
            var urlLeft = url.substring(0, jingPosit);//url左侧部分
            let urlp = setCode('state')
            console.log(urlp)
            if(urlp && urlp != 'null'){
              console.log('走了这里1')
              window.location = urlLeft + "#" + urlp;//拼接跳转
              removeItem('url')
              return
            }
              console.log('走了这里2')
              window.location = urlLeft + "#/";//拼接跳转
          }
        });
      }
    },
    // 获取验证码
    sendSmsCode() {
      if (!this.mobile) {
        Toast("请输入手机号");
        return;
      }

      let data = {
        mobile: this.mobile,
      };
      sendSms(data).then((res) => {
        if (res.code != 1000) {
          Toast.fail(res.message);
          return;
        }
        this.isSmsSend = true;
        this.countDown();
      });
    },
    countDown() {
      this.timer = setInterval(() => {
        this.sendBtnText = `${this.counter}秒后重新发送`;
        this.counter--;
        if (this.counter < 0) {
          this.reset();
        }
      }, 1000);
    },
    reset() {
      this.isSmsSend = false;
      this.sendBtnText = "发送验证码";
      if (this.timer) {
        clearInterval(this.timer);
        this.counter = 60;
        this.timer = null;
      }
    },
    buttomClick() {
      let data = {
        mobile: this.mobile,
        code: this.Verification,
      };
      bindWechat(data).then((res) => {
        if (res.code != 1000) {
          Toast.fail(res.message);
          return;
        }
        // store.dispatch("wxOat",res.data)
        store.dispatch("info");
        Toast.clear();
        this.$router.push(this.$route.query.redirect || "/"); // 登录成功返回进入首页页面/
      });
    },
  },
};
</script>

<style scoped>
.tibOX {
  padding: 10px 20px;
  box-sizing: border-box;
}
.widhtbox {
  margin-top: 10px;
}
</style>